<template>
	<div class="app-container">
		<el-header class="new-header">
			<div class="new-header-left">客户列表</div>
			<div class="new-beader-right">
				<el-button type="primary" @click="handleLinkToAddCustomer"
					>添加客户</el-button
				>
				<!--                <el-button type="primary" @click="handleLinkToImportCustomer">导入客户</el-button>-->
			</div>
		</el-header>
		<el-main>
			<el-form ref="searchForm" :inline="true" :model="searchForm">
				<el-form-item label="姓名：">
					<el-input
						v-model="searchForm.customerName"
						placeholder="姓名"
						size="small"
					></el-input>
				</el-form-item>
				<el-form-item label="手机号:">
					<el-input
						v-model="searchForm.customerPhone"
						placeholder="手机号"
						size="small"
					></el-input>
				</el-form-item>
				<el-form-item label="公司名称:">
					<el-input
						v-model="searchForm.companyName"
						placeholder="公司名称"
						size="small"
					></el-input>
				</el-form-item>
				<el-form-item label="销售:">
					<el-select
						v-model="searchForm.salesId"
						placeholder="销售"
						size="small"
						clearable
						filterable
						@clear="searchForm.salesId = null"
					>
						<el-option
							v-for="item in sales"
							:key="item.userId"
							:label="item.userName"
							:value="item.userId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="创建时间:">
					<el-date-picker
						v-model="dateRange"
						type="datetimerange"
						size="small"
						value-format="yyyy-MM-dd HH:mm:ss"
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="handleDataRangeChange"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="客户状态:">
					<el-select
						v-model="searchForm.customerState"
						placeholder="客户状态"
						size="small"
						clearable
						filterable
						@clear="searchForm.customerState = null"
					>
						<el-option
							v-for="item in customerStateList"
							:key="item.id"
							:label="item.value"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签:">
					<el-select
						v-model="searchForm.customerType"
						placeholder="标签"
						size="small"
						clearable
						filterable
						@clear="searchForm.customerType = null"
					>
						<el-option
							v-for="item in customerTypeList"
							:key="item.id"
							:label="item.value"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit" size="small"
						>搜索</el-button
					>
					<el-button
						type="primary"
						@click="handleInitBatchCustomerAssignment"
						size="small"
						>批量分派</el-button
					>
					<el-button
						type="primary"
						@click="handleBatchDeleteCustomer"
						size="small"
						>批量删除</el-button
					>
          <el-button
              type="primary"
              @click="handleExportsExcel"
              icon="search"
              size="small"
          >导出
          </el-button>
				</el-form-item>
			</el-form>
			<el-table
				:data="list"
				v-loading="loading"
				stripe
				style="width: 100%"
				border
				@selection-change="handleSelectionChange"
			>
				<el-table-column
					type="selection"
					width="50"
					align="center"
				></el-table-column>
				<el-table-column type="index" width="50" label="编号" align="center">
				</el-table-column>
				<el-table-column
					prop="customerName"
					label="客户姓名"
					align="center"
				></el-table-column>
				<el-table-column prop="customerPhone" label="联系电话" align="center">
				</el-table-column>
				<el-table-column
					prop="companyName"
					label="公司名称"
					align="center"
				></el-table-column>
        <el-table-column label="地址"  align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ scope.row.address }}</span>
          </template>
        </el-table-column>
				<el-table-column
					prop="industry"
					label="行业"
					align="center"
				></el-table-column>
				<el-table-column prop="customerTypeName" align="center" label="标签">
					<template slot-scope="scope">
						<el-select
							v-model="scope.row.customerType"
							placeholder="标签"
							filterable
							@change="
								(val) => {
									handleModifyType(val, scope.row)
								}
							"
						>
							<el-option
								v-for="item in customerTypeList"
								:key="item.id"
								:value="item.id"
								:label="item.value"
							></el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column
					prop="customerStateName"
					align="center"
					label="客户状态"
				>
					<template slot-scope="scope">
						<el-select
							v-model="scope.row.customerState"
							placeholder="客户状态"
							filterable
							@change="
								(val) => {
									handleModifyState(val, scope.row)
								}
							"
						>
							<el-option
								v-for="item in customerStateList"
								:key="item.id"
								:value="item.id"
								:label="item.value"
							></el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column
					prop="salesName"
					align="center"
					label="销售"
				></el-table-column>
				<el-table-column
					prop="createTime"
					align="center"
					label="创建时间"
				></el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<el-button @click="handleClick(scope.row)" type="text" size="small"
							>查看</el-button
						>
						<el-button
							@click="handleInitAddFollowInfo(scope.row)"
							type="text"
							size="small"
							>添加跟进</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-block">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageVo.current"
					:page-sizes="[10, 20, 50, 100, 500]"
					:page-size="pageVo.size"
					layout="total, sizes, prev, pager, next, jumper"
					:total="totalCount"
				>
				</el-pagination>
			</div>
		</el-main>
		<el-dialog title="批量分派" :visible.sync="dialogForAssignment">
			<el-form
				:model="assignmentForm"
				label-position="right"
				label-width="120px"
				ref="assignmentForm"
			>
				<el-form-item
					label="销售"
					prop="salesId"
					:rules="{ required: true, message: '请选择销售', trigger: 'change' }"
				>
					<el-select
						v-model="assignmentForm.salesId"
						placeholder="销售"
						clearable
						filterable
					>
						<el-option
							v-for="item in sales"
							:key="item.userId"
							:label="item.userName"
							:value="item.userId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="已选客户">
					<el-table
						:data="selectedCustomers"
						stripe
						border
						width="100%"
						height="300"
					>
						<el-table-column
							prop="customerName"
							label="客户姓名"
							align="center"
						></el-table-column>
						<el-table-column
							prop="customerPhone"
							label="联系电话"
							align="center"
						>
						</el-table-column>
						<el-table-column
							prop="companyName"
							label="公司名称"
							align="center"
						></el-table-column>
						<el-table-column
							prop="profession"
							label="职业"
							align="center"
						></el-table-column>
						<el-table-column
							prop="industry"
							label="行业"
							align="center"
						></el-table-column>
						<el-table-column
							prop="customerTypeName"
							align="center"
							label="标签"
						></el-table-column>
					</el-table>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button type="primary" plain @click="dialogForAssignment = false"
					>取消</el-button
				>
				<el-button type="primary" @click="handleBatchCustomerAssignment"
					>确认</el-button
				>
			</div>
		</el-dialog>
		<el-dialog
			title="添加跟进"
			:close-on-click-modal="false"
			:visible.sync="dialogForFollow"
			top="30vh"
			width="30%"
		>
			<el-row type="flex" justify="center">
				<el-col :span="16">
					<el-form
						:model="followInfoForm"
						ref="followInfoForm"
						:rules="rules"
						label-width="100px"
						label-position="right"
					>
						<el-form-item label="跟进类型" prop="followType">
							<el-select
								v-model="followInfoForm.followType"
								filterable
								placeholder="跟进类型"
								style="width: 100%"
							>
								<el-option
									v-for="item in CustomerFollowTypeList"
									:key="item.id"
									:value="item.id"
									:label="item.value"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="跟进内容" prop="content">
							<el-input
								type="textarea"
								v-model="followInfoForm.content"
								placeholder="跟进内容"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="跟进时间" prop="followTime">
							<el-date-picker
								type="datetime"
								v-model="followInfoForm.followTime"
								placeholder="跟进时间"
								format="yyyy-MM-dd HH:mm:ss"
							>
							</el-date-picker>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer">
				<el-button type="primary" @click="handleAddFollowInfo">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import {
  batchCustomerAssignment,
  batchCustomerDelete,
  createCustomerFollow,
  editCustomerStatus, exportTaskList,
  getCustomerInfoList,
  getUserListOfMerchantByPage,
  initCustomerFollow,
  initCustomerInfo,
} from '../../api'
	export default {
		data() {
			return {
				loading: false,
				list: [],
				userId: this.$store.getters.userId,
				dialogForAssignment: false,
				assignmentForm: {
					salesId: null,
					customerIdList: [],
				},
				searchForm: {
					userId: this.$store.getters.userId,
					customerName: '',
					customerPhone: '',
					companyName: '',
					salesId: null,
					customerState: null,
					customerType: null,
					startTime: '',
					endTime: '',
          exportType: 1
				},
				dateRange: [],
				pageVo: {
					current: 1,
					size: 10,
				},
				totalCount: 0,
				customerTypeList: [],
				customerStateList: [],
				sales: [],
				selectedCustomers: [],
				dialogForFollow: false,
				CustomerFollowTypeList: [],
				followInfoForm: {
					followType: null,
					content: null,
					customerId: null,
					followTime: null,
				},
				rules: {
					followType: [
						{ required: true, message: '请选择跟进类型', trigger: 'change' },
					],
					content: [
						{ required: true, message: '请输入跟进内容', trigger: 'blur' },
					],
		
				},
			}
		},
		created() {
			this.initSelectOptions()
			this.loadCustomerList()
		},
		methods: {
      async handleExportsExcel() {
        this.$confirm(`确定导出任务客户报表吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        }).then(() => {
          exportTaskList({ ...this.searchForm });
        });
      },
			async handleInitAddFollowInfo(row) {
				const data = await initCustomerFollow({ userId: this.userId })
				this.CustomerFollowTypeList = data.CustomerFollowTypeList
				this.followInfoForm.customerId = row.customerId
				this.dialogForFollow = true
				this.$nextTick(() => {
					this.$refs.followInfoForm.clearValidate()
				})
			},
			handleAddFollowInfo() {
				this.$refs.followInfoForm.validate((valid) => {
					if (valid) {
						let param = {
							userId: this.userId,
							...this.followInfoForm,
						}
						createCustomerFollow(param).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									message: '添加跟进成功',
									title: '成功',
								})
								this.dialogForFollow = false
								this.followInfoForm.followType = null
								this.followInfoForm.content = null
								this.followInfoForm.followTime = null
								this.loadCustomerList()
							}
						})
					}
				})
			},
			async handleModifyState(val, row) {
				let param = {
					userId: this.userId,
					customerId: row.customerId,
					customerState: val,
				}
				let data = await editCustomerStatus(param)
				if (data) {
					this.$notify({
						type: 'success',
						message: '修改客户状态成功',
						title: '成功',
					})
				}
			},
			async handleModifyType(val, row) {
				let param = {
					userId: this.userId,
					customerId: row.customerId,
					customerType: val,
				}
				let data = await editCustomerStatus(param)
				if (data) {
					this.$notify({
						type: 'success',
						message: '修改标签成功',
						title: '成功',
					})
				}
			},
			handleToCall(phone) {
				if (phone) {
					this.$router.push({ name: 'call', query: { customerPhone: phone } })
				}
			},
			handleInitBatchCustomerAssignment() {
				if (this.selectedCustomers.length) {
					this.dialogForAssignment = true
				} else {
					this.$message.error('请选择客户后操作')
				}
			},
			handleBatchDeleteCustomer() {
				if (this.selectedCustomers.length) {
					this.$confirm(
						`确认删除选择的${this.selectedCustomers.length}条客户信息吗?`,
						'批量删除',
						{
							type: 'warning',
							showCancelButton: true,
							cancelButtonText: '取消',
							confirmButtonText: '确认',
						}
					)
						.then(() => {
							let param = {
								userId: this.userId,
								customerIdList: this.selectedCustomers.map(
									(item) => item.customerId
								),
							}
							batchCustomerDelete(param).then((data) => {
								if (data) {
									this.$notify({
										title: '成功',
										message: '批量删除客户成功',
										type: 'success',
									})
									this.loadCustomerList()
								}
							})
						})
						.catch((e) => {
							console.log(e)
						})
				} else {
					this.$message.error('请选择客户后操作')
				}
			},
			handleBatchCustomerAssignment() {
				this.$refs.assignmentForm.validate((valid) => {
					if (valid) {
						this.assignmentForm.customerIdList = this.selectedCustomers.map(
							(item) => item.customerId
						)
						let param = {
							userId: this.userId,
							...this.assignmentForm,
						}
						batchCustomerAssignment(param).then((data) => {
							if (data) {
								this.$notify({
									title: '成功',
									message: '批量分派客户成功',
									type: 'success',
								})
								this.dialogForAssignment = false
								this.loadCustomerList()
							}
						})
					}
				})
			},
			handleSelectionChange(val) {
				this.selectedCustomers = val
			},
			handleLinkToAddCustomer() {
				this.$router.push(`/addCustomer`)
			},
			handleLinkToImportCustomer() {
				this.$router.push(`/importCustomer?from=customerList`)
			},
			async initSelectOptions() {
				let data = await initCustomerInfo()
				this.customerStateList = data.customerStateList
				this.customerTypeList = data.customerTypeList
				let sales = await getUserListOfMerchantByPage({
					current: 1,
					size: 500,
					params: { userId: this.userId },
				})
				this.sales = sales.records
			},
			handleDataRangeChange(value) {
				//时间选择器触发事件
				if (value) {
					this.searchForm.startTime = value[0]
					this.searchForm.endTime = value[1]
				} else {
					this.searchForm.startTime = ''
					this.searchForm.endTime = ''
				}
			},
			onSubmit() {
				this.pageVo.current = 1
				this.loadCustomerList()
			},
			handleSizeChange(val) {
				this.pageVo.size = val
				this.loadCustomerList()
			},
			handleCurrentChange(val) {
				this.pageVo.current = val
				this.loadCustomerList()
			},
			loadCustomerList() {
				let params = {
					...this.pageVo,
					params: this.searchForm,
				}
				this.loading = true
				getCustomerInfoList(params).then((data) => {
					this.list = data.records
					this.totalCount = data.total
					this.loading = false
				})
			},
			handleClick(row) {
				this.$router.push({
					name: 'customerDetails',
					query: {
						id: row.customerId,
					},
				})
			},
		},
	}
</script>
